import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Section.module.scss';

const Section = ({ className, containerClassName, children }) => (
  <section className={cx(styles.root, className)}>
    <div className={cx(styles.container, containerClassName)}>{children}</div>
  </section>
);

Section.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  children: PropTypes.node,
};
Section.defaultProps = {
  className: undefined,
  containerClassName: undefined,
  children: undefined,
};

export default Section;
