import React from 'react';
import { Link } from '@reach/router';
import logoUrl from '../../assets/logo.svg';
import styles from './Header.module.scss';

const phoneNo = '1-800-535-1950';

const Header = () => (
  <div className={styles.root}>
    <Link className={styles.logo} to="/">
      <img src={logoUrl} alt="Mercury Resources" />
    </Link>
    <a className={styles.emergency} href={`tel:${phoneNo}`}>
      In case of emergency call <strong className={styles.emergencyPhone}>{phoneNo}</strong>
    </a>
  </div>
);

export default Header;
