import React, { Fragment } from 'react';
import AboutSection from './AboutSection';
import ContactsSection from './ContactsSection';
import styles from './HomePage.module.scss';

const HomePage = () => (
  <Fragment>
    <AboutSection />
    <ContactsSection className={styles.contactsSection} title="Contact Us" />
  </Fragment>
);

export default HomePage;
