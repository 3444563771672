import React from 'react';
import Section from '../../common/Section';
import styles from './AboutSection.module.scss';

const AboutSection = () => (
  <Section>
    <div className={styles.description}>
      <p>
        <strong>Mercury Resources LLC</strong> is a privately held exploration
        and production company. Our current focus is operating wells in the
        Briar Creek Madison field in Williams and McKenzie counties in North
        Dakota.
      </p>
    </div>
  </Section>
);

export default AboutSection;
