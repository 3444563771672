import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Item.module.scss';

const Item = ({ title, titleUnderlined, subtitle, children }) => (
  <li className={styles.root}>
    <h2 className={cx(styles.title, titleUnderlined && styles.titleUnderlined)}>
      {title}
    </h2>
    <h3 className={styles.subtitle}>{subtitle}</h3>
    <div className={styles.content}>{children}</div>
  </li>
);

Item.propTypes = {
  title: PropTypes.string.isRequired,
  titleUnderlined: PropTypes.bool,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
Item.defaultProps = {
  titleUnderlined: false,
};

export default Item;
