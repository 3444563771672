import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Section from '../../common/Section';
import Contacts from '../../common/Contacts';
import Map from '../../common/Map';
import styles from './ContactsSection.module.scss';

const ContactsSection = ({ className, title }) => (
  <Section
    className={cx(styles.root, className)}
    containerClassName={styles.container}
  >
    <div className={styles.details}>
      {title && <h1 className={styles.title}>{title}</h1>}
      <Contacts
        address
        phone="(303) 495-5455"
        fax="(303) 495-5455"
        email="info@mercuryresourcesllc.com"
        hours
      />
    </div>
    <div className={styles.map}>
      <Map height={400} />
    </div>
  </Section>
);

ContactsSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
ContactsSection.defaultProps = {
  className: undefined,
  title: undefined,
};

export default ContactsSection;
