import React from 'react';
import Section from './Section';
import logoUrl from '../../assets/logo.svg';
import styles from './Footer.module.scss';

const Footer = () => (
  <Section className={styles.root} containerClassName={styles.container}>
    <img className={styles.logo} src={logoUrl} alt="Mercury Resources" />
    <div className={styles.copyright}>
      © {new Date().getFullYear()} Mercury Resources LLC. All rights reserved.
    </div>
  </Section>
);

export default Footer;
