import React from 'react';
import PropTypes from 'prop-types';

const API_KEY = 'AIzaSyBhfuwLdcfRiNrdj4XgBgkjlTnwJagYEew';

const Map = ({ height }) => (
  <iframe
    src={`https://www.google.com/maps/embed/v1/place?q=1805%20Shea%20Center%20Dr%2C%20Suite%20250%20Highland%20Ranch%2C%20CO%2080129&key=${API_KEY}`}
    allowFullScreen
    title="Mercury Resources LLC location"
    width="100%"
    height={height}
    frameBorder="0"
    style={{ border: 0 }}
  />
);

Map.propTypes = {
  height: PropTypes.number.isRequired,
};

export default Map;
