import React, { Component } from 'react';
import { Router } from '@reach/router';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import SliderSection from './components/common/SliderSection';
import NavSection from './components/common/NavSection';
import HomePage from './components/pages/HomePage';
import InterestOwnersPage from './components/pages/InterestOwnersPage';
import ManagementPage from './components/pages/ManagementPage';
import ContactsPage from './components/pages/ContactsPage';
import styles from './App.module.scss';

class App extends Component {
  render() {
    return (
      <div className={styles.root}>
        <Header />
        <div className={styles.body}>
          <SliderSection />
          <NavSection />
          <Router>
            <HomePage path="/" />
            <InterestOwnersPage path="interest-owners" />
            <ManagementPage path="management" />
            <ContactsPage path="contacts" />
          </Router>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
