import React from 'react';
import Items, { Item } from '../common/Items';
import Contacts from '../common/Contacts';
import Section from '../common/Section';

const InterestOwnersPage = () => (
  <Section>
    <Items>
      <Item
        title="Revenue Inquiries"
        titleUnderlined
        subtitle="Terri Kimrey"
      >
        <Contacts
          phone="(303) 495-5455"
          email="tkimrey@mercuryresourcesllc.com"
        />
      </Item>
      <Item
        title="Owner Relations and Surface Inquiries"
        titleUnderlined
        subtitle="Conrad Woodland"
      >
        <Contacts
          phone="(303) 495-5455"
          email="cwoodland@mercuryresourcesllc.com"
        />
      </Item>
    </Items>
  </Section>
);

export default InterestOwnersPage;
