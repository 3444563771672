import React from 'react';
import { Link } from '@reach/router';
import styles from './NavSection.module.scss';

const NavSection = () => (
  <ul className={styles.root}>
    <li className={styles.item}>
      <Link className={styles.link} to="/">
        Home
      </Link>
    </li>
    <li className={styles.item}>
      <Link className={styles.link} to="interest-owners">
        Interest Owners
      </Link>
    </li>
    <li className={styles.item}>
      <Link className={styles.link} to="management">
        Management
      </Link>
    </li>
    <li className={styles.item}>
      <Link className={styles.link} to="contacts">
        Contacts
      </Link>
    </li>
  </ul>
);

export default NavSection;
