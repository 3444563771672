import React from 'react';
import PropTypes from 'prop-types';
import {
  Briefcase as HoursIcon,
  Mail as EmailIcon,
  MapPin as AddressIcon,
  Phone as PhoneIcon,
  Printer as FaxIcon,
} from 'react-feather';
import styles from './Contacts.module.scss';

const Contacts = ({ address, phone, fax, email, hours }) => (
  <ul className={styles.root}>
    {address && (
      <li className={styles.line}>
        <AddressIcon className={styles.icon} />
        <div className={styles.text}>
          <strong>Mercury Resources LLC</strong>
          <br />
          1805 Shea Center Dr, Suite 250 Highland Ranch, CO 80129
        </div>
      </li>
    )}
    {phone && (
      <li className={styles.line}>
        <PhoneIcon className={styles.icon} />
        <div className={styles.text}>
          <a href={`tel:${phone}`}>{phone}</a>
        </div>
      </li>
    )}
    {fax && (
      <li className={styles.line}>
        <FaxIcon className={styles.icon} />
        <div className={styles.text}>
          <a href={`tel:${fax}`}>{fax}</a>
        </div>
      </li>
    )}
    {email && (
      <li className={styles.line}>
        <EmailIcon className={styles.icon} />
        <div className={styles.text}>
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </li>
    )}
    {hours && (
      <li className={styles.line}>
        <HoursIcon className={styles.icon} />
        <div className={styles.text}>
          <ul className={styles.hours}>
            <li>
              <span className={styles.day}>Mon</span> 9:00 am – 4:00 pm
            </li>
            <li>
              <span className={styles.day}>Tue</span> 9:00 am – 4:00 pm
            </li>
            <li>
              <span className={styles.day}>Wed</span> 9:00 am – 4:00 pm
            </li>
            <li>
              <span className={styles.day}>Thu</span> 9:00 am – 4:00 pm
            </li>
            <li>
              <span className={styles.day}>Fri</span> 9:00 am – 4:00 pm
            </li>
            <li>
              <span className={styles.day}>Sat</span> Closed
            </li>
            <li>
              <span className={styles.day}>Sun</span> Closed
            </li>
          </ul>
        </div>
      </li>
    )}
  </ul>
);

Contacts.propTypes = {
  address: PropTypes.bool,
  phone: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string,
  hours: PropTypes.bool,
};
Contacts.defaultProps = {
  address: false,
  phone: undefined,
  fax: undefined,
  email: undefined,
  hours: false,
};

export default Contacts;
