import React from 'react';
import Items, { Item } from '../common/Items';
import Contacts from '../common/Contacts';
import Section from '../common/Section';

const ManagementPage = () => (
  <Section>
    <Items>
      <Item title="Conrad Woodland" subtitle="President">
        <Contacts
          phone="(303) 495-5455"
          email="cwoodland@mercuryresourcesllc.com"
        />
      </Item>
    </Items>
  </Section>
);

export default ManagementPage;
